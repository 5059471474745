import * as Sentry from '@sentry/browser';

// Initialize Sentry
if (window.sentryConfig) {
	Sentry.init({
		dsn: window.sentryConfig.dsn,
		environment: window.sentryConfig.environment,
		beforeSend: function (event) {
			event.logger = 'javascript';

			return event;
		},
	});
}
