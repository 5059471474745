document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('map')) {
		return;
	}

	window.initMap = function () {

		// Vytvoreni mapy
		var map = new google.maps.Map(document.getElementById('map'), {
			center: {
				lat: 49.227566,
				lng: 16.572663,
			},
			zoom: 14,
			disableDefaultUI: true,
			styles: [
				{
					featureType: 'administrative',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#b51881',
						},
					],
				},
				{
					featureType: 'administrative',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'all',
					stylers: [
						{
							color: '#edfdfa',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'all',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'all',
					stylers: [
						{
							color: '#cdf8f1',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#03dcbb',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#ffffff',
						},
						{
							visibility: 'on',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'transit',
					elementType: 'all',
					stylers: [
						{
							visibility: 'simplified',
						},
						{
							color: '#cdf8f1',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'all',
					stylers: [
						{
							color: '#82d3f4',
						},
						{
							visibility: 'on',
						},
					],
				},
			],
		});

		// Vytvoreni info bubliny
		var infoBubble = new google.maps.InfoWindow({
			content: 'Adbros s.r.o.',
		});

		// Vytvoreni markeru
		var marker = new google.maps.Marker({
			position: {
				lat: 49.229077,
				lng: 16.585022,
			},
			map: map,
			title: 'Adbros s.r.o.',
			icon: {
				path: 'M11 2c-3.9 0-7 3.1-7 7 0 5.3 7 13 7 13 0 0 7-7.7 7-13 0-3.9-3.1-7-7-7Zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5 0-1.4 1.1-2.5 2.5-2.5 1.4 0 2.5 1.1 2.5 2.5 0 1.4-1.1 2.5-2.5 2.5Z',
				scale: 2.2727272727272727272727272727,
				anchor: new google.maps.Point(11, 22),
				fillOpacity: 1,
				fillColor: '#b51881',
				strokeOpacity: 0,
			},
		});

		// Otevreni bubliny
		marker.addListener('click', function () {
			infoBubble.open(map, marker);
		});
	};

	// Pridani Google Maps API
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://maps.googleapis.com/maps/api/js?key=' + window.googleMapsKey + '&callback=initMap';
	document.head.appendChild(script);
});
