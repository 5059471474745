document.addEventListener('DOMContentLoaded', function () {

	// Slider s hodnotami
	if (document.getElementById('values-slider')) {
		(function () {
			var slider = document.getElementById('values-slider');
			var icons = slider.querySelector('.icons');
			var values = slider.querySelector('.values');

			function slideLeft () {
				// Momentalne zobrazeny slide
				var visibleSlide = values.querySelector('div.visible');
				visibleSlide.classList.remove('visible');

				// Prepnuti na predchozi slide
				var slideTo = visibleSlide.previousElementSibling;
				if (!slideTo) {
					slideTo = visibleSlide.parentNode.querySelector('div:last-of-type');
				}
				slideTo.classList.add('visible');

				// Uprava tridy kvuli barve pozadi
				values.classList.remove(visibleSlide.getAttribute('data-value'));
				values.classList.add(slideTo.getAttribute('data-value'));

				// Prepnuti ikon
				icons.removeChild(icons.querySelector('div:last-of-type'));
				icons.querySelector('div:last-of-type').classList.add('invisible');
				icons.querySelector('div:first-of-type').classList.remove('invisible');

				// Ikona do zasoby nalevo
				var duplicateIcon = icons.querySelector('div:nth-last-of-type(2)').cloneNode(true);
				duplicateIcon.classList.add('invisible');
				icons.insertBefore(duplicateIcon, icons.firstChild);
			}

			function slideRight () {
				// Momentalne zobrazeny slide
				var visibleSlide = values.querySelector('div.visible');
				visibleSlide.classList.remove('visible');

				// Prepnuti na predchozi slide
				var slideTo = visibleSlide.nextElementSibling;
				if (!slideTo) {
					slideTo = visibleSlide.parentNode.querySelector('div:first-of-type');
				}
				slideTo.classList.add('visible');

				// Uprava tridy kvuli barve pozadi
				values.classList.remove(visibleSlide.getAttribute('data-value'));
				values.classList.add(slideTo.getAttribute('data-value'));

				// Prepnuti ikon
				icons.removeChild(icons.querySelector('div:first-of-type'));
				icons.querySelector('div:first-of-type').classList.add('invisible');
				icons.querySelector('div.invisible:last-of-type').classList.remove('invisible');

				// Ikona do zasoby napravo
				var duplicateIcon = icons.querySelector('div:nth-of-type(2)').cloneNode(true);
				duplicateIcon.classList.add('invisible');
				icons.appendChild(duplicateIcon);
			}

			// Klik na sipku vlevo
			values.querySelector('.arrow:first-of-type').addEventListener('click', function (e) {
				e.preventDefault();
				slideLeft();
			});

			// Swipe vpravo
			slider.addEventListener('swiped-right', slideLeft);

			// Klik na dalsi
			values.querySelector('.arrow:last-of-type').addEventListener('click', function (e) {
				e.preventDefault();
				slideRight();
			});

			// Swipe vlevo
			slider.addEventListener('swiped-left', slideRight);
		})();
	}
});
