document.addEventListener('DOMContentLoaded', function () {

	if (document.getElementById('career-form')) {

		// Vyber souboru ve formulari
		document.addEventListener('change', function (e) {

			// Jedna se o input[type="file"] ?
			if (e.target.tagName.toLowerCase() == 'input' && e.target.type.toLowerCase() == 'file') {

				// Ukazat soubor, pokud je vybrany
				if (e.target.files && e.target.files[0]) {
					var fileName = e.target.files[0].name;
					var arr = fileName.split('.');

					e.target.parentNode.querySelector('span').innerHTML = '<small>' + arr[arr.length - 1] + '</small> ' + fileName;
				}
				else {
					e.target.parentNode.querySelector('span').innerHTML = '';
				}
			}
		});

		/* // Odeslani formulare
		document.getElementById('career-form').addEventListener('submit', function (e) {
			e.preventDefault();

			this.classList.add('sent');
			this.querySelector('.message').innerHTML = 'Děkujeme za Váš zájem u nás pracovat. Brzy se Vám ozveme.';
		}); */

	}

	if (!document.getElementById('career')) {
		return;
	}

	// Otevirani/zavirani pozic
	document.querySelectorAll('#job-positions .row > div').forEach(function (div) {
		var textDiv = div.querySelector('.text');

		// Kliknuti na nadpis
		div.querySelector('h3').addEventListener('click', function () {
			toggleVisibility();
		});

		// Kliknuti na sipku
		div.querySelector('a.arrow').addEventListener('click', function (e) {
			e.preventDefault();
			toggleVisibility();
		});

		// Funkce pro zmenu viditelnosti
		function toggleVisibility () {
			if (!textDiv.classList.contains('open')) {
				textDiv.classList.add('open');
				textDiv.querySelector('.wrapper').style.height = textDiv.querySelector('.wrapper').scrollHeight + 'px';
			}
			else {
				textDiv.classList.remove('open');
				textDiv.querySelector('.wrapper').style.removeProperty('height');
			}
		}
	});
});
