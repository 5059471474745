document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('products')) {
		return;
	}

	// Funkcionalita galerie
	document.querySelectorAll('.image-gallery').forEach(function (gallery) {

		var descriptions = gallery.querySelectorAll('.description a');
		var controls = gallery.querySelectorAll('.controls a');
		var slider = gallery.querySelector('.slider');

		// Prepinani obrazku
		function switchSlide (n) {
			n = parseInt(n);

			// Aktualizovat popisy
			descriptions.forEach(function (d) {
				d.classList.remove('selected');
			});
			gallery.querySelector('.description a[data-slide="' + n + '"]').classList.add('selected');

			// Aktualizovat obrazky
			slider.style.transform = 'translateX(-' + ((n - 1) * 100) + '%)';

			// Aktualizovat tecky
			controls.forEach(function (c) {
				c.classList.remove('selected');
			});
			gallery.querySelector('.controls a[data-slide="' + n + '"]').classList.add('selected');
		}

		// Klikani na popisy
		descriptions.forEach(function (a) {
			a.addEventListener('click', function (e) {
				e.preventDefault();
				switchSlide(this.getAttribute('data-slide'));
			});
		});

		// Klikani na tecky
		controls.forEach(function (a) {
			a.addEventListener('click', function (e) {
				e.preventDefault();
				switchSlide(this.getAttribute('data-slide'));
			});
		});

		// Swipe vlevo
		gallery.addEventListener('swiped-left', function () {
			var currentSlide = parseInt(gallery.querySelector('.controls .selected').getAttribute('data-slide'));
			var lastSlide = parseInt(gallery.querySelector('.controls a:last-of-type').getAttribute('data-slide'));

			if (currentSlide + 1 <= lastSlide) {
				switchSlide(currentSlide + 1);
			}
		});

		// Swipe vpravo
		gallery.addEventListener('swiped-right', function () {
			var currentSlide = parseInt(gallery.querySelector('.controls .selected').getAttribute('data-slide'));
			var firstSlide = parseInt(gallery.querySelector('.controls a:first-of-type').getAttribute('data-slide'));

			if (currentSlide - 1 >= firstSlide) {
				switchSlide(currentSlide - 1);
			}
		});
	});
});
