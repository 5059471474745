document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('home')) {
		return;
	}

	// Slider s klienty
	document.querySelector('#our-clients > a:nth-of-type(1)').addEventListener('click', function (e) {
		e.preventDefault();

		var visibleSlide = document.querySelector('#our-clients > div > div.visible');
		visibleSlide.classList.remove('visible');

		var slideTo = visibleSlide.previousElementSibling;
		if (!slideTo) {
			slideTo = document.querySelector('#our-clients > div > div:last-of-type');
		}
		slideTo.classList.add('visible');
	});
	document.querySelector('#our-clients > a:nth-of-type(2)').addEventListener('click', function (e) {
		e.preventDefault();

		var visibleSlide = document.querySelector('#our-clients > div > div.visible');
		visibleSlide.classList.remove('visible');

		var slideTo = visibleSlide.nextElementSibling;
		if (!slideTo) {
			slideTo = document.querySelector('#our-clients > div > div:first-of-type');
		}
		slideTo.classList.add('visible');
	});

	// Animace pri doskrolovani ke klientum
	window.animate(document.getElementById('our-clients'));
});
