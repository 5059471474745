// NodeList.forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}

// initialize ajax
document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));


// Funkcionalita pro responzivni video
(function () {

	// Breakpointy definovane v CSS
	var breakpoints = {
		mobileWidth: '32rem',
		mobilePlusWidth: '36rem',
		tabletWidth: '76.8rem',
		desktopWidth: '110rem',
		pageWidth: '144rem',
	};

	// Defaultni velikost pisma v % definovana v CSS
	var defaultFontSize = 62.5;

	// Zpozdeni po resize eventu [ms]
	var resizeDelay = 100;

	// Promenna, ktera drzi timeout pri resize eventu
	var resizeTimeout = null;

	// Funkce pro vytvoreni media query z REM jednotky
	var createMediaQuery = function (rem) {

		// Ziskat cislo
		var remSize = parseFloat(rem.replace('rem', ''));

		// Odecist 1 px
		remSize -= 0.1;

		// Prevest REM na EM jednotky, v zavislosti na defaultni nastavene velikosti fontu
		var emSize = remSize * defaultFontSize / 100;

		// Vratit breakpoint v EM
		return '(max-width: ' + emSize + 'em)';
	};

	// Funkce pro nastaveni spravneho "src" videa
	function resizeVideo (video) {
		var src = '';
		var windowWidth = Math.min(window.innerWidth, 1440) * window.devicePixelRatio;

		// Projit vsechny <source>
		video.querySelectorAll('source').forEach(function (source) {

			if (src != '') {
				return;
			}

			// Ma <source> specifikovane urcite "data-media" a pokud ano, je aktualni okno mensi nez sirka dana v "data-media"?
			var media = source.getAttribute('data-media');
			if (media !== null) {
				var mediaQuery = createMediaQuery(breakpoints[media]);

				// Jestli sirka okna neodpovida, prerusit zpracovavani
				if (!window.matchMedia(mediaQuery).matches) {
					return;
				}
			}

			// Ziskat srcsety
			var srcset = source.getAttribute('srcset').split(',');
			var i = 0;

			// Trim vsech srcsetu
			for (i = 0; i < srcset.length; i++) {
				srcset[i] = srcset[i].trim();
			}

			// Ziskat src videa a sirku, pro ktere se hodi
			for (i = 0; i < srcset.length; i++) {
				var arr = srcset[i].split(' ');

				srcset[i] = {};
				srcset[i].src = arr[0];
				if (arr[1]) {
					srcset[i].width = parseInt(arr[1].replace('w', ''));
				}
			}

			// Projit vsechny srcsety a nastavit spravne src
			for (i = 0; i < srcset.length; i++) {
				src = srcset[i].src;
				if (srcset[i].width && windowWidth <= srcset[i].width) {
					break;
				}
			}
		});

		// Pokud ma video jine src, nez je zadane, tak prepsat src
		var currentFilename = video.src.split('/');
		currentFilename = currentFilename[currentFilename.length - 1];

		var suggestedFilename = src.split('/');
		suggestedFilename = suggestedFilename[suggestedFilename.length - 1];

		if (currentFilename != suggestedFilename) {
			video.src = src;
		}
	}

	// Po nacteni dokumentu
	document.addEventListener('DOMContentLoaded', function () {

		// Inicializovat videa
		document.querySelectorAll('video').forEach(resizeVideo);

		// Listener na resize okna - pripadna zmena vsech videi (zpozdit o "resizeDelay")
		window.addEventListener('resize', function () {

			if (resizeTimeout !== null) {
				clearTimeout(resizeTimeout);
			}

			resizeTimeout = setTimeout(function () {
				document.querySelectorAll('video').forEach(resizeVideo);
			}, resizeDelay);
		});
	});
})();


document.addEventListener('DOMContentLoaded', function () {
	// SVG <use> polyfill
	window.svg4everybody();

	// MAGIC: Touchstart listener zpusobi, ze v iOS se zacnou pouzivat CSS :active styly apod.
	window.addEventListener('touchstart', function () {
		return;
	});

	// Zobrazeni mobilniho menu
	if (document.querySelector('#show-menu a')) {
		document.querySelector('#show-menu a').addEventListener('click', function (e) {
			e.preventDefault();
			this.parentNode.parentNode.classList.toggle('show');
		});
	}

	// Detekce IE a Edge
	if (document.documentMode || /Edge/.test(navigator.userAgent)) {
		this.body.classList.add('ie');
	}

	// Slider s lidma
	if (document.querySelector('.people-slider')) {
		document.querySelectorAll('.people-slider').forEach(function (slider) {

			function slideLeft () {
				var visibleSlide = slider.querySelector('div.visible');
				visibleSlide.classList.remove('visible');

				var slideTo = visibleSlide.previousElementSibling;
				if (!slideTo) {
					slideTo = visibleSlide.parentNode.lastElementChild;
				}
				slideTo.classList.add('visible');
			}

			function slideRight () {
				var visibleSlide = slider.querySelector('div.visible');
				visibleSlide.classList.remove('visible');

				var slideTo = visibleSlide.nextElementSibling;
				if (!slideTo) {
					slideTo = visibleSlide.parentNode.firstElementChild;
				}
				slideTo.classList.add('visible');
			}

			// Klik na sipku vlevo
			slider.querySelector('.arrow:first-of-type').addEventListener('click', function (e) {
				e.preventDefault();
				slideLeft();
			});

			// Swipe vpravo
			slider.addEventListener('swiped-right', slideLeft);

			// Klik na sipku vpravo
			slider.querySelector('.arrow:last-of-type').addEventListener('click', function (e) {
				e.preventDefault();
				slideRight();
			});

			// Swipe vlevo
			slider.addEventListener('swiped-left', slideRight);
		});
	}
});


// Funkce pro pridani .animate tridy po doskrolovani k dane sekci
window.animate = function (elm) {

	if (window.innerWidth < 768) {
		var triggerHeight = -100;
	}
	else {
		var triggerHeight = -200;
	}

	function checkPosition () {
		if (elm.getBoundingClientRect().top - window.innerHeight <= triggerHeight) {
			elm.classList.add('animate');
			window.removeEventListener('scroll', checkPosition);
		}
	}

	window.addEventListener('scroll', checkPosition);
	checkPosition();
};
