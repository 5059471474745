// Element.closest, Element.matches polyfill - start
if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function (s) {
		var el = this;

		do {
			if (el.matches(s)) {
				return el;
			}
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}
// Element.closest, Element.matches polyfill - end

(function () {
	var videosState = {};

	var videoActions = {
		play: 'start',
		pause: 'pause',
		resume: 'resume',
		ended: 'complete',
		seek: 'seeked',
		waiting: 'buffering',
		progress: [
			25,
			50,
			75,
			100,
		],
	};

	function sendGaVideoEvent (videoFilename, videoAction, sendValue) {
		var gaObj = {
			event: 'GAEvent',
			eventCategory: 'HTML5 Video',
			eventAction: videoAction,
			eventLabel: videoFilename,
		};
		if (typeof sendValue !== 'undefined' && sendValue !== '') {
			gaObj.eventValue = sendValue;
		}

		if (!Array.isArray(window.dataLayer)) {
			// 	('dataLayer not an array, quitting..');
			return;
		}

		// console.log('data to push:', gaObj);
		window.dataLayer.push(gaObj);
		// console.log('pushed to dataLayer array');
		// console.log('dataLayer:', window.dataLayer);
	}

	function getSrcFilename (src) {
		var filenameArr = src.split('/');
		var filename = filenameArr[filenameArr.length - 1];

		return filename;
	}

	function liveEventTrack (eventName, selector, eventCallback, capture) {
		capture = capture || false;
		document.addEventListener(eventName, function (e) {
			if (e.target.matches(selector + ', ' + selector + ' *')) {
				eventCallback.apply(e.target.closest(selector), arguments);
			}
		}, capture);
	}

	liveEventTrack('click', '[data-gtm-push]', function () {
		var _this = this;
		// console.log('data-gtm-push found on:', _this);

		if (!Array.isArray(window.dataLayer)) {
			// console.log('dataLayer not an array, quitting..');
			return;
		}

		var parsedJson = JSON.parse(_this.dataset.gtmPush);
		// console.log('data to push:', parsedJson);

		window.dataLayer.push(parsedJson);
		// console.log('pushed to dataLayer array');
		// console.log('dataLayer:', window.dataLayer);
	}, false);

	// Video events
	liveEventTrack('play', 'video', function () {
		var _this = this;
		if (_this.seeking) {
			return;
		}
		var currentTime = _this.currentTime;
		var eventAction = (currentTime === 0) ? videoActions.play : videoActions.resume;
		var filename = getSrcFilename(_this.currentSrc);
		sendGaVideoEvent(filename, eventAction, currentTime);
	}, true);

	liveEventTrack('pause', 'video', function () {
		var _this = this;
		if (_this.seeking) {
			return;
		}
		var currentTime = _this.currentTime;
		var totalTime = _this.duration;
		var eventAction = videoActions.pause;
		var filename = getSrcFilename(_this.currentSrc);

		if (Math.floor(currentTime) !== Math.floor(totalTime)) {
			sendGaVideoEvent(filename, eventAction, currentTime);
		}
	}, true);

	liveEventTrack('seeked', 'video', function () {
		var _this = this;
		var currentTime = _this.currentTime;
		var eventAction = videoActions.seek;
		var filename = getSrcFilename(_this.currentSrc);

		sendGaVideoEvent(filename, eventAction, currentTime);
	}, true);

	liveEventTrack('timeupdate', 'video', function () {
		var _this = this;
		var currentTime = _this.currentTime;
		var totalTime = _this.duration;
		var filename = getSrcFilename(_this.currentSrc);
		var eventAction;

		if (typeof videosState[filename] === 'undefined') {
			videosState[filename] = 0;
		}

		var videoPercent = Math.floor(100 * Math.round(currentTime) / totalTime);

		for (var i = 0; i < videoActions.progress.length; i++) {
			var isNotSeek = currentTime < ((videoActions.progress[i] / 100 * totalTime) + 3);
			if (videoPercent >= videoActions.progress[i] && isNotSeek && videoActions.progress[i] > videosState[filename]) {
				eventAction = videoActions.progress[i] + '%';
				videosState[filename] = videoActions.progress[i];
			}
		}

		if (typeof eventAction !== 'undefined') {
			sendGaVideoEvent(filename, eventAction, currentTime);
		}
	}, true);

	liveEventTrack('ended', 'video', function () {
		var _this = this;
		var currentTime = _this.currentTime;
		var eventAction = videoActions.ended;
		var filename = getSrcFilename(_this.currentSrc);
		videosState[filename] = 0;
		sendGaVideoEvent(filename, eventAction, currentTime);
	}, true);

})();
