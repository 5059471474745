document.addEventListener('DOMContentLoaded', function () {
	document.querySelectorAll('.video-container').forEach(function (videoContainer) {
		var video = videoContainer.querySelector('.video-container__video');
		var playOverlay = videoContainer.querySelector('.video-container__play-overlay');

		playOverlay.addEventListener('click', function () {
			video.play();
			video.focus();
		});

		video.addEventListener('play', function () {
			videoContainer.classList.add('is-playing');
			video.controls = true;
		});

		video.addEventListener('ended', function () {
			videoContainer.classList.remove('is-playing');
			video.load();
			video.controls = false;
		});
	});
});
