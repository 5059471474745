document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('clients')) {
		return;
	}

	// Otevirani/zavirani klienta
	document.querySelectorAll('#all-clients .row > div').forEach(function (div) {
		var textDiv = div.querySelector('.text');

		// Kliknuti na logo
		div.querySelector('img').addEventListener('click', function () {
			toggleVisibility();
		});

		// Kliknuti na sipku
		div.querySelector('a.arrow').addEventListener('click', function (e) {
			e.preventDefault();
			toggleVisibility();
		});

		// Funkce pro zmenu viditelnosti
		function toggleVisibility () {
			if (!textDiv.classList.contains('open')) {
				textDiv.classList.add('open');
				textDiv.querySelector('.wrapper').style.height = textDiv.querySelector('.wrapper').scrollHeight + 'px';
			}
			else {
				textDiv.classList.remove('open');
				textDiv.querySelector('.wrapper').style.removeProperty('height');
			}
		}
	});
});
